import * as React from "react"
import { graphql } from "gatsby"


// icons
import iconEmail from "../images/icons/email.svg"
import iconPlace from "../images/icons/place.svg"
import iconPhone from "../images/icons/phone.svg"

// components
import Layout from '../components/layout'
import ContactForm from '../components/contact-form'
import { Trans, useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { useCallback } from 'react'
import { useLocalStorage } from 'usehooks-ts'
import { useState } from 'react'
import SkeletonColunaContato from '../components/skeleton-coluna-contato'

// seo
const seo = {
  title: 'seo.contact.title',
  description: 'seo.contact.description'
}

const ContatoPage = () => {
  const [userCountry, setUserCountry] = useState("DEFAULT");
  const [acceptedCookies, _] = useLocalStorage('consentCookieHidden', false);
  const [loading, setLoading] = useState(false);
  const [IPFound, setIPFound] = useState(false);

  const { t } = useTranslation('contato');

  const getUserIP = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetch('https://ipapi.co/country/');
      if (response.ok) {
        const country = await response.text();
        setUserCountry(country === 'PT' ? 'PT' : 'DEFAULT');
      }
    } catch (error) {
      console.error('An error occurred fetching the IP data');
    } finally {
      setIPFound(true);
      setLoading(false);
    }
  }, [])

  useEffect(() => {
    if (!IPFound && acceptedCookies) getUserIP();
  }, [acceptedCookies, getUserIP]);

  const contactInfo = {
    DEFAULT: {
      email: ['contato@vericode.com.br'],
      addresses: ['brazil', 'canada', 'portugal'],
      phones: ['brazil', 'portugal']
    },
    PT: {
      email: ['contato@vericode.pt'],
      addresses: ['portugal'],
      phones: ['portugal']
    }
  }

  const LoadingContactInfo = () => {
    return (
      <>
        <SkeletonColunaContato />
        <SkeletonColunaContato />
        <SkeletonColunaContato />
      </>
    )
  }

  const ContactInfo = () => {
    return (
      <>
        <div className="flex flex-col items-center text-center">
          <img className="mb-sm" src={iconEmail} alt="address-sign-icon" />
          {
            contactInfo[userCountry].email.map((email) => (
              <a key={`email-${email}`} href={`mailto:${email}`}>{email}</a>
            ))
          }
        </div>

        <div className="flex flex-col items-center text-center space-y-sm">
          <img src={iconPlace} alt="map-marker-icon" />
          {
            contactInfo[userCountry].addresses.map((country) => (
              <address className='flex flex-col' key={`address-${country}`} vocab="http://schema.org/" typeof="PostalAddress">
                <span property="addressLocality"><Trans i18nKey={`address.${country}.street`} /></span>
                <span property="addressRegion"><Trans i18nKey={`address.${country}.zip`} /></span>
                <span property="addressCountry"><Trans i18nKey={`address.${country}.city`} /></span>
              </address>
            ))
          }
        </div>

        <div className="flex flex-col items-center text-center space-y-sm">
          <img src={iconPhone} alt="phone-icon" />
          {
            contactInfo[userCountry].phones.map((country) => (
              <div key={`phone-${country}`}>
                <a href={`tel:${t(`phone.${country}`)}`}>{t(`phone.${country}`)}</a>
                <p className='text-sm'>{t(`workdays.${country}`)}</p>
              </div>
            ))
          }
        </div>
      </>
    )
  }

  return (
    <>
      <Layout seo={seo}>
        <section className="pageHeader">
          <div className="container">
            <h1><Trans i18nKey={seo.title} /></h1>
            <h2 className="title title--display"><Trans i18nKey={seo.description} /></h2>
          </div>
        </section>

        <section className="mb-lg">
          <div className="container grid lg:grid-cols-3 gap-md">
            {loading ? <LoadingContactInfo /> : <ContactInfo />}
          </div>
        </section>

        <div className="containerFluid">
          <section className="rounded section section--nopadding section--light">
            <div className="container lg:flex py-lg">
              <ContactForm />
            </div>
          </section>
        </div>
      </Layout>
    </>
  )
}

export default ContatoPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["contato", "contact-form", "layout", "header", "footer"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;