import React from 'react';

const SkeletonColunaContato = () => {
  return (
    <div className="flex flex-col items-center text-center space-y-sm w-full">
      <div className='rounded-full w-16 h-16 animate-pulse bg-gray-200' />
      <address className='flex flex-col w-full items-center space-y-xs' >
        <span className='animate-pulse rounded-sm h-6 w-4/5 bg-gray-200'></span>
        <span className='animate-pulse rounded-sm h-6 w-3/4 bg-gray-200'></span>
      </address>
    </div>

  );
};

export default SkeletonColunaContato;
